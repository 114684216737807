import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import i18n from "@/libs/i18n/index.js";
import * as enums from "@/utils/enum.js";

const state = {
  supportedLanguages: [],
  appConfigs: {},
};

const mutations = {
  [mutationTypes.SET_SUPPORTED_LANGUAGES](state, languages) {
    state.supportedLanguages = languages;
  },
  [mutationTypes.SET_APP_CONFIGS](state, config) {
    state.appConfigs = {
      ...state.appConfigs,
      [config.key]: config.value,
    };
  },
};

const actions = {
  async [actionTypes.GET_SUPPORTED_LANGUAGES]({ commit, state }) {
    if (state.supportedLanguages.length > 0) {
      return;
    }
    const { data: result } = await httpClient.get("/general/languages");
    if (result.success) {
      const lang = localStorage.getItem("lang");
      i18n.locale = lang || result.data.find((x) => x.isDefault).languageCode;
      commit(mutationTypes.SET_SUPPORTED_LANGUAGES, result.data);
    } else {
      i18n.locale = process.env.VUE_APP_LOCALE;
    }
    localStorage.setItem("lang", i18n.locale);
  },
  async [actionTypes.GET_APP_CONFIG_BY_KEY]({ commit, state }, key) {
    if (state.appConfigs[key]) {
      return state.appConfigs[key];
    }

    const { data: result } = await httpClient.get(`/appconfig/${key}`);
    if (result.success) {
      commit(mutationTypes.SET_APP_CONFIGS, { key, value: result.data });
      return result.data;
    }

    return null;
  },
};

const getters = {
  supportedLanguages: (state) => state.supportedLanguages,
  appConfigs: (state) => state.appConfigs,
  unitOfLengths: () => {
    return Object.keys(enums.UnitOfLength).map((key) => {
      return {
        key,
        value: enums.UnitOfLength[key],
      };
    });
  },
  fuelTypes: () => {
    return Object.keys(enums.FuelType).map((key) => {
      return {
        key,
        value: enums.FuelType[key],
      };
    });
  },
  fuelCosts: () => {
    return Object.keys(enums.FuelCost).map((key) => {
      return {
        key,
        value: enums.FuelCost[key],
      };
    });
  },
  engineTypes: () => {
    return Object.keys(enums.EngineType).map((key) => {
      return {
        key,
        value: enums.EngineType[key],
      };
    });
  },
  charterTypes: () => {
    return Object.keys(enums.CharterType).map((key) => {
      return {
        key,
        value: enums.CharterType[key],
      };
    });
  },
  cancellationPolicies: () => {
    return Object.keys(enums.CancellationPolicy).map((key) => {
      return {
        key,
        value: enums.CancellationPolicy[key],
      };
    });
  },
  rentalPeriods: () => {
    return Object.keys(enums.RentalPeriod).map((key) => {
      return {
        key,
        value: enums.RentalPeriod[key],
      };
    });
  },
  boatDocumentTypes: () => {
    return Object.keys(enums.BoatDocumentType).map((key) => {
      return {
        key,
        value: enums.BoatDocumentType[key],
      };
    });
  },
  invoiceCountries: () => {
    return Object.keys(enums.InvoiceCountry).map((key) => {
      return {
        key,
        value: enums.InvoiceCountry[key],
      };
    });
  },
  currencies: () => {
    return Object.keys(enums.Currency).map((key) => {
      return {
        key,
        value: enums.Currency[key],
      };
    });
  },
  remainingPaymentTypes: () => {
    return Object.keys(enums.RemainingPaymentType).map((key) => {
      return {
        key,
        value: enums.RemainingPaymentType[key],
      };
    });
  },
  bookingStages: () => {
    return Object.keys(enums.BookingStage).map((key) => {
      return {
        key,
        value: enums.BookingStage[key],
      };
    });
  },
  policyTypes: () => {
    return Object.keys(enums.PolicyType).map((key) => {
      return {
        key,
        value: enums.PolicyType[key],
      };
    });
  },
  boatServicePeriods: () => {
    return Object.keys(enums.BoatServicePeriod).map((key) => {
      return {
        key,
        value: enums.BoatServicePeriod[key],
      };
    });
  },
  weekDays: () => {
    return Object.keys(enums.WeekDay).map((key) => {
      return {
        key,
        value: enums.WeekDay[key],
      };
    });
  },
  servicePricingTypes: () => {
    return Object.keys(enums.ServicePricingType).map((key) => {
      return {
        key,
        value: enums.ServicePricingType[key],
      };
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
